import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTiles from "../components/globalTiles"
import LocationMap from "../components/locationMap"
import ImageTween from "../components/imageTween"

const Location = () => {
  const lang = "en"
  // const [tab, setTab] = useState("1")

  return (
    <Layout lang={lang} translation="/pl/lokalizacja/">
      <SEO title="Location" />

      <section className="home-top">
        <div className="global__padd">
          <div className="home-top__wrapper">
            <h2>
              UNITY <br /> <span>Centre</span>
            </h2>
            <h2>Krakow's Business Complex</h2>
          </div>
          <ImageTween
            src={require("../assets/images/location-top-image.jpg")}
            srcSet={require("../assets/images/location-top-image@2x.jpg")}
            alt=""
          />
        </div>
      </section>

      <section className="location-top">
        <div className="global__padd--bigger">
          <h2>
            UNITY CENTRE is a 50 000 SQM complex in the heart of Krakow, that
            includes a 26-floor “AA” class office tower, two office buildings, a
            four star hotel and apartment building.
          </h2>
        </div>
      </section>

      <section className="location-neighborhood">
        <div className="location-neighborhood__wrapper global__padd--bigger">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2>The Neighborhood</h2>
              <p>
                UNITY CENTRE complex lies prominently on Rondo Mogilskie and is
                located in a close walking distance from the Main Railway
                Station with a shopping mall. On top of this exceptional
                location, you will find yourself between the intellectual
                atmosphere of Krakow University of Economics and peaceful
                greenesses of Botanic Garden.
              </p>
            </div>
            <div className="col-md-5 offset-md-1">
              <img
                className="img-fluid"
                src={require("../assets/images/location-map-europe-en.jpg")}
                srcSet={require("../assets/images/location-map-europe-en@2x.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="location-neighborhood__map">
          <LocationMap lang={lang} />
        </div>

        <div className="location-neighborhood__info global__padd">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../assets/images/location-info-ico-1.svg")}
                  alt=""
                />
                <div>
                  <strong>2,8km / 30min</strong>
                  <span>to the Wawel Hill</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../assets/images/location-info-ico-2.svg")}
                  alt=""
                />
                <div>
                  <strong>2km / 20min</strong>
                  <span>
                    to Market Square, The Cloth Hall and St. Mary’s Basilica
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../assets/images/location-info-ico-3.svg")}
                  alt=""
                />
                <div>
                  <strong>700m / 5min</strong>
                  <span>to the Main Railway Station</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../assets/images/location-info-ico-4.svg")}
                  alt=""
                />
                <div>
                  <strong>19min</strong>
                  <span>
                    suburban rail ‘point-to-point’ transfer from the Main
                    Railway Station to Krakow Balice Airport
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../assets/images/location-info-ico-5.svg")}
                  alt=""
                />
                <div>
                  <strong>50</strong>
                  <span>public transport lines</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-neighborhood__info-ico">
                <img
                  src={require("../assets/images/location-info-ico-6.svg")}
                  alt=""
                />
                <div>
                  <strong>700m / 5min</strong>
                  <span>to the ‘’Galeria Krakowska” shopping mall</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="location-content global__padd">
        <div className="row no-gutters flex-column-reverse flex-md-row">
          <div className="col-md-6">
            <ImageTween
              src={require("../assets/images/location-content-image-1-map.jpg")}
              srcSet={require("../assets/images/location-content-image-1-map@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="location-content__inner">
              <h2>Mobility Hub</h2>
              <p>
                Our complex provides easy access to the world. With Balice
                Airport only a 30-minute drive or 17-minute train trip with
                Krakow Main Railway Station situated a one kilometer away, 16-24
                Lubomirskiego Street is a strategically well-balanced address.
              </p>
            </div>
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col-md-7">
            <div className="location-content__inner">
              <p>
                UNITY CENTRE has been designed to support our community with a
                wide range of transport services.
              </p>
              <p>
                Commute by foot, bike, scooter, train, bus or car. 400 parking
                spaces with spots for charging electric cars and 200 cycle racks
                will make arriving at work a breeze.
              </p>
            </div>
          </div>
          <div className="col-md-5">
            <ImageTween
              src={require("../assets/images/location-content-image-2.jpg")}
              srcSet={require("../assets/images/location-content-image-2@2x.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>

      {
        // <section className="location-infographic">
        //   <div className="global__padd--bigger">
        //     <p>
        //       Our further infrastructure improvements will intimately link the
        //       complex to the surrounding neighbourhoods via modernized access road
        //       and a clearer traffic grid.
        //     </p>
        //   </div>
        //
        //   <div className="location-infographic__wrapper"></div>
        //   <div className="location-infographic__nav">
        //     <button
        //       className={tab === "1" ? "current" : ""}
        //       onClick={e => setTab("1")}
        //     >
        //       <span>Foot</span>
        //       <img src={require("../assets/images/ico-foot.svg")} alt="" />
        //     </button>
        //     <button
        //       className={tab === "2" ? "current" : ""}
        //       onClick={e => setTab("2")}
        //     >
        //       <span>Bike</span>
        //       <img src={require("../assets/images/ico-bike.svg")} alt="" />
        //     </button>
        //     <button
        //       className={tab === "3" ? "current" : ""}
        //       onClick={e => setTab("3")}
        //     >
        //       <span>Scooter</span>
        //       <img src={require("../assets/images/ico-scooter.svg")} alt="" />
        //     </button>
        //     <button
        //       className={tab === "4" ? "current" : ""}
        //       onClick={e => setTab("4")}
        //     >
        //       <span>Train</span>
        //       <img src={require("../assets/images/ico-train.svg")} alt="" />
        //     </button>
        //     <button
        //       className={tab === "5" ? "current" : ""}
        //       onClick={e => setTab("5")}
        //     >
        //       <span>Bus</span>
        //       <img src={require("../assets/images/ico-bus.svg")} alt="" />
        //     </button>
        //     <button
        //       className={tab === "6" ? "current" : ""}
        //       onClick={e => setTab("6")}
        //     >
        //       <span>Car</span>
        //       <img src={require("../assets/images/ico-car.svg")} alt="" />
        //     </button>
        //   </div>
        // </section>
      }

      <GlobalTiles lang={lang} />
    </Layout>
  )
}

export default Location
